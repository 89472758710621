import React from "react";
import "./stars.scss";

let arr = [] as any[];
for (let i = 0; i < (window.innerWidth < 510 ? 100 : 100); i++ ) {
  arr.push(i);
}
export default class Stars extends React.Component<any, any> {
  render() {
    return (
      <>
      {arr.map((_x: any,) => {
         return <div key={_x} className={"star"}></div>
        })
      }
      </>
    );
  }
}