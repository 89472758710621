import React from "react";
import "./custom_cursor.scss";

interface CustomCursorProps {}

interface CustomCursorState {
  curX: number;
  curY: number;
}

const cursorSizeInPx = 20;

export default class CustomCursor extends React.Component<CustomCursorProps, CustomCursorState> {
  constructor(_props: CustomCursorProps) {
    super(_props);
    this.state = {
      curX: -100,
      curY: -100
    };
  }

  componentDidMount() {
    let me = this;
    document.addEventListener("mousemove", (_ev: MouseEvent) => {
      me.setState({
        curX: _ev.clientX,
        curY: _ev.clientY
      });
    });
  }

  componentWillUnmount() {
    document.removeEventListener("mousemove", () => {});
  }

  render() {
    let me = this;
    return <div className={"custom-cursor-field"}>
      <div className={"cursor"} style={{position: "absolute", top: `calc(${me.state.curY - cursorSizeInPx / 2}px)`, left: `${me.state.curX - cursorSizeInPx / 2 }px`}}></div>
    </div>;
  }
}