import React from "react";
import "./about_us.scss";

export default class AboutUs extends React.Component<any, any> {
  render() {
    const me = this;
    return <div className={"open-source"}>
      <h3>Демократизация ИИ - наша миссия</h3>
      <p>Мир не стоит на месте: возникают новые вызовы и потребности. Единственным путем преодоления ограничений является <b>открытость технологий, данных</b> и <b>инклюзивное сообщество</b>.</p>
      <p style={{color: "var(--white)"}}>Кластер проводит мероприятия, развивющие открытые продукты, датасеты и сообщество.&nbsp;<span onClick={() => {me.props.setSide(1);}} style={{textShadow: "0px 0px 5px rgba(0,0,200, 0.2)", fontWeight: 900, color: "var(--main-color)"}}>Далее</span></p>
    </div>
  }
}