import React from "react";
import "./registration.scss";

interface RegistrationState {
  email: string,
  sent: boolean,
  error: string | null;
  loading: boolean;
}

export default class Registration extends React.Component<any, RegistrationState> {

  constructor(_props: any) {
    super(_props);
    this.state = {
      email: "",
      sent: false,
      error: null,
      loading: false
    };
    this.sendData = this.sendData.bind(this);
  }

  sendData() {
    const me = this;
    const url = `https://клии.рф/api/participants/sign_up`;
    me.setState({
      error: null,
      loading: true
    }, () => {
      fetch(url, {method: "POST", headers: {"Content-Type": "application/json"}, body: JSON.stringify({email: me.state.email})})
        .then((_res) => {
          return _res.json()
        }).then((_res) => {
          if (_res["_embedded"]) {
            me.setState({loading: false, error: _res["_embedded"]["errors"].map((_e: any) => _e.message.replace("Internal Server Error:", "")).join(";")});
            return;
          }
        localStorage.setItem("jwt", _res.jwt_token.content);
        me.setState({sent: true, loading: false});
      }).catch((_e: any) => {
        me.setState({sent: false, error: JSON.stringify(_e), loading: false})
      });
    });
  }

  render() {
    const me = this;
    return <div className={"registration"} onClick={me.props.stopRegistration}>
      <div className={"registration-content"} onClick={(_e) => {
        _e.stopPropagation();
      }}>
        <h3>Регистрация</h3>
        <input placeholder={"Email"}
               onKeyDown={(_e: React.KeyboardEvent<HTMLInputElement>) => {
                  if ("Enter" === _e.key) {
                    me.sendData();
                  }
                }
               }
               type={"email"}
               style={{width: "100%", fontSize: "16px"}}
               onChange={(_ev: React.ChangeEvent<HTMLInputElement>) => {
                 me.setState({
                   email: _ev.target.value.replace(/[^a-zA-Z0-9\.\-\@]/g, '')}
                 );
                }
              }
        />
        {me.state.error && <p style={{marginTop: "10px", color: "var(--red)"}}>{me.state.error}</p> }
        { !me.state.sent ? me.state.email.length > 4 && me.state.email.indexOf("@") > -1 &&
            <button onClick={me.sendData}
                    style={me.state.loading ? {opacity: 0.6, pointerEvents: "none"} : {} }>Регистрация</button> :
            <>
              <p style={{marginTop: "20px", width: "100%"}}>Спасибо, Ваша заявка принята!<br/><br/>При появлении новых мероприятий, открытий или мастер-классов на почту <b>{me.state.email}</b> будут приходить приглашения.</p>
              <button onClick={() => {
                document.location.reload();
              }}>ВПЕРЕД!</button>
            </>
        }
        <p style={{fontSize: "10px", marginTop: "20px"}}>Продолжая регистрацию, вы соглашаетесь с <u>политикой обработки данных</u></p>
      </div>
    </div>;
  }
}