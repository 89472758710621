import React from "react";
import "./law_stuff.scss";
const links = [
  ["Политика конфиденциальности", "#"],
  ["Правила пользования", "#"],
  ["Политика возврата средств", "#"],
] as any[];
export default class LawStuff extends React.Component<any, any> {
  render() {

    return <div className={"lawstuff"}>
      {
        links.map((_l, _lIndex) => {
          return <a key={`link-${_lIndex}`} href={_l[1]}>{_l[0]}</a>;
      })
      }
    </div>
  }
}