import React from 'react';
import './App.css';
import CubeMenu from "./components/cube_menu/CubeMenu";
import Stars from "./components/stars/Stars";
import LaserText from "./components/cool_text/LaserText";
import LeftMenu from "./components/left_menu/LeftMenu";
import LawStuff from "./components/law_stuff/LawStuff";
import CustomCursor from "./components/custom_cursor/CustomCursor";
import AboutUs from "./pages/about_us/AboutUs";
import Contests from "./pages/contests/Contests";
import Events from "./pages/events/Events";
import Community from "./pages/community/Community";
import Registration from "./pages/registration/Registration";
import Grants from "./pages/grants/Grants";
import Education from "./pages/education/Education";

const base = window.innerWidth < 510 ? `ИИ` : `Кластер_ИИ`;

export default class App extends React.Component<any, any> {
  initialized = false;
  cubeRef = React.createRef<CubeMenu>();
  data: any = [];

  constructor(props: any) {
    super(props);
    const me = this;
    this.state = {
      title: "Кластер_ИИ",
      activeSide: 1
    };

    me.data = [
      [() => {me.setState({title: `${base}#О_нас`}, () => {me.setSide(0);}); }, "О_нас"],
      [() => {me.setState({title: `${base}#Контесты`}, () => {me.setSide(1);}); }, "Контесты"],
      [() => {me.setState({title: `${base}#Мероприятия`}, () => {me.setSide(2);}); }, "Мероприятия"],
      [() => {me.setState({title: `${base}#Сообщество`}, () => {me.setSide(3);}); }, "Сообщество"],
      [() => {me.setState({title: `${base}#Гранты`}, () => {me.setSide(4);}); }, "Гранты"],
      [() => {me.setState({title: `${base}#Образование`}, () => {me.setSide(5);}); }, "Образование"],
    ];

    this.setSide = this.setSide.bind(this);
    this.startRegistration = this.startRegistration.bind(this);
    this.stopRegistration = this.stopRegistration.bind(this);
  }

  startRegistration() {
    const me = this;
    me.setState({registration: true})
  }

  stopRegistration() {
    const me = this;
    me.setState({registration: false})
  }

  componentDidMount() {
    if (this.initialized) return;
    this.initialized = true;
    let me = this;
    document.addEventListener("scroll", (_ev: Event) => {
      let y = window.scrollY;
      const incr = (window.innerWidth < 510) ? 0.5 : 0.7;
      let cubeNum = 6 * y / (incr * document.documentElement.scrollHeight);
      me.setSide(Math.floor(cubeNum));
    });
    me.setSide(0);
  }

  setSide(_sideNumber: number) {
    const me = this;
    const activeCube = me.data[Math.floor(_sideNumber)] ? me.data[Math.floor(_sideNumber)][1] : me.data[Object.keys(me.data).length - 1][1];

    me.setState({activeSide: activeCube, title: `${base}#${activeCube}`}, () => {
      me.cubeRef?.current?.setSide(_sideNumber + 1);
    });
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", () => {});
  }

  render() {
    let me = this;
    return (
      <div style={{height: "1800vh", background: "linear-gradient(0deg, #FFF, #2A197C, #00043C, #2000BB, #3A1B58, #030303)"}}>
        <Stars/>
        <div style={{position: "fixed", left: "10px", top: "10px", display: "flex", flexDirection: "column"}}>
          <LaserText key={me.state.title} text={me.state.title} subText={""}/>
        </div>
        {/*<img style={{position: "fixed", top: "10vh", left: "0"}} src={pic}/>*/}

        <LeftMenu elements={
          me.data
        } activeSide={me.state.activeSide}/>

        <div style={{ position: "fixed",
                      top: 0,
                      left: window.innerWidth < 510 ? "-25px" : "20px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      width: "100vw",
                      marginTop: "12vh"}}>
          <CubeMenu
            ref={me.cubeRef}
            activeSize={1}
            side1={
              <AboutUs setSide={me.setSide} />
            }
            side2={
              <Contests setSide={me.setSide} />
            }
            side3={
              <Events setSide={me.setSide} />
            }
            side4={
              <Community startRegistration={me.startRegistration}
                         setSide={me.setSide} />
            }
            side5={
              <Grants setSide={me.setSide}
                        startRegistration={me.startRegistration} />
            }
            side6={
              <Education startRegistration={me.startRegistration}
                         setSide={me.setSide} />
            }
          />

        </div>
        <LawStuff/>
        <CustomCursor/>
        { me.state.registration && <Registration stopRegistration={me.stopRegistration}/> }
      </div>
    );
  }
}

