import React from "react";
import "./left_menu.scss";

interface LeftMenuProps {
  elements: any[];
  activeSide: number;
}

interface LeftMenuState {
  activeMenu: boolean;
}

export default class LeftMenu extends React.Component<LeftMenuProps, LeftMenuState> {
  timeoutToClose: any;
  timeoutToIgnore: any;
  ignoreInput: boolean = false;

  constructor(_props: LeftMenuProps) {
    super(_props);
    this.state = {
      activeMenu: false
    };
  }

  render() {
    const me = this;

    return <>
        <div className={`menu-list ${me.state.activeMenu ? 'active' : ''}`}>
          {
            me.props.elements.map((_e, _eIndex) => {
              return <div onClick={() => {
                if (window.innerWidth < 510) {
                  clearTimeout(me.timeoutToClose);
                  _e[0]();
                  me.setState({activeMenu: false});
                  return;
                }
                _e[0]();
              } } className={`item-container`}>
                <div className={`item ${(_e[1] === me.props.activeSide) ? 'active-item' : ''}`}>
                  {_e[1]}
                </div>
              </div>
            })
          }
          <div onClick={(_ev) => {
            if (window.innerWidth <= 510) return;
            _ev.stopPropagation();
            me.setState({activeMenu: !me.state.activeMenu}, () => {
              clearTimeout(me.timeoutToClose);
              me.timeoutToClose = setTimeout(() => {me.setState({activeMenu: false})}, 3000);
            });
          }}
         onTouchMove={(_ev) => {
           if (window.innerWidth > 510) return;
           if (me.ignoreInput) return;
           _ev.stopPropagation();
           me.ignoreInput = true;
           me.timeoutToIgnore = setTimeout(() => {this.ignoreInput = false;}, 100 );
           me.setState({activeMenu: !me.state.activeMenu}, () => {
             clearTimeout(me.timeoutToClose);
             me.timeoutToClose = setTimeout(() => {me.setState({activeMenu: false})}, 3000);
           });
         }}
          className={"activation-bar"}>
              <div className={"activation-bar-line"}></div>
          </div>
      </div>
      {/*<i className={"fal fa-bars fa-2x open-button"} onClick={() => {*/}
      {/*  me.setState({activeMenu: !me.state.activeMenu});*/}
      {/*}}/>*/}
    </>;
  }
}