import React from "react";
import "./community.scss";

export default class Community extends React.Component<any, any> {
  render() {
    const me = this;
    return <div className={"community"}>
      <h2>Зарегистрируйтесь</h2>
      <p>Чтобы участвовать в мероприятиях, мастер-классах и других активностях, улучшающих общество, технологии и Вашу жизнь</p>
      <br/>
      <button onClick={me.props.startRegistration} >Регистрация</button>
    </div>
  }
}