import React from "react";
import "./style/led_number.scss";

interface LedNumberProps {
  number: number,
  size: number
}

interface LedNumberState {
}

const on = "#fff";
const off = "transparent";

const ledSticks = {
  "0": {
    top: {
      borderTopColor: on,
      borderLeftColor: on,
      borderRightColor: on,
      borderBottomColor: off
    },
    bottom: {
      borderTopColor: off,
      borderLeftColor: on,
      borderRightColor: on,
      borderBottomColor: on
    }
  },
  "1": {
    top: {
      borderTopColor: off,
      borderLeftColor: off,
      borderRightColor: on,
      borderBottomColor: off,
    },
    bottom: {
      borderTopColor: off,
      borderLeftColor: off,
      borderRightColor: on,
      borderBottomColor: off
    }
  },
  "2": {
    top: {
      borderTopColor: on,
      borderLeftColor: off,
      borderRightColor: on,
      borderBottomColor: on,
    },
    bottom: {
      borderTopColor: on,
      borderLeftColor: on,
      borderRightColor: off,
      borderBottomColor: on
    }
  },
  "3": {
    top: {
      borderTopColor: on,
      borderLeftColor: off,
      borderRightColor: on,
      borderBottomColor: on
    },
    bottom: {
      borderTopColor: on,
      borderLeftColor: off,
      borderRightColor: on,
      borderBottomColor: on
    }
  },
  "4": {
    top: {
      borderTopColor: off,
      borderLeftColor: on,
      borderRightColor: on,
      borderBottomColor: on,
    },
    bottom: {
      borderTopColor: on,
      borderLeftColor: off,
      borderRightColor: on,
      borderBottomColor: off,
    }
  },
  "5": {
    top: {
      borderTopColor: on,
      borderLeftColor: on,
      borderRightColor: off,
      borderBottomColor: on
    },
    bottom: {
      borderTopColor: on,
      borderLeftColor: off,
      borderRightColor: on,
      borderBottomColor: on
    }
  },
  "6": {
    top: {
      borderTopColor: on,
      borderLeftColor: on,
      borderRightColor: off,
      borderBottomColor: on
    },
    bottom: {
      borderTopColor: on,
      borderLeftColor: on,
      borderRightColor: on,
      borderBottomColor: on
    }
  },
  "7": {
    top: {
      borderTopColor: on,
      borderLeftColor: on,
      borderRightColor: on,
      borderBottomColor: off
    },
    bottom: {
      borderTopColor: off,
      borderLeftColor: off,
      borderRightColor: on,
      borderBottomColor: off,
    }
  },
  "8": {
    top: {
      borderTopColor: on,
      borderLeftColor: on,
      borderRightColor: on,
      borderBottomColor: on,
    },
    bottom: {
      borderTopColor: on,
      borderLeftColor: on,
      borderRightColor: on,
      borderBottomColor: on,
    }
  },
  "9": {
    top: {
      borderTopColor: on,
      borderLeftColor: on,
      borderRightColor: on,
      borderBottomColor: on,
    },
    bottom: {
      borderTopColor: on,
      borderLeftColor: off,
      borderRightColor: on,
      borderBottomColor: on
    }
  }
} as any;


export default class LedNumber extends React.Component<LedNumberProps, LedNumberState> {
  constructor(_props: LedNumberProps) {
    super(_props);
  }

  render() {
    const me = this;
    return <div className={"container"}>
      <div style={{...ledSticks[me.props.number].top, width: `${me.props.size}px`, height: `${me.props.size}px`}}
           className={"top-box"}></div>
      <div style={{...ledSticks[me.props.number].bottom, width: `${me.props.size}px`, height: `${me.props.size}px`}}
           className={"bottom-box"}></div>
    </div>;
  }
}