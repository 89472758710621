import React from "react";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
import "./lazer_text.scss";


interface LaserTextProps {
  text: string;
  subText: string;
}

export default class LaserText extends React.Component<LaserTextProps, any> {
  mounted: boolean = false;

  componentDidMount() {
    if (this.mounted) return;
    this.mounted = true;

    Splitting();
  }

  render() {
    let me = this;
    return <div style={{display: "flex"}}>
      <div className="title">
        <span className="text write" data-splitting="lines">
          {me.props.text}
        </span>
        <span aria-hidden="true" className="text laser" data-splitting="lines">
          {me.props.text}
        </span>
      </div>
    </div>;
  }
}