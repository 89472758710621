import React from "react";
import "./contests.scss";
import LedNumber from "../../components/cool_stuff/led_number/LedNumber";

export default class Contests extends React.Component<any, any> {
  render() {
    const me = this;

    return <div className={"contests"}>
      <h2>Контесты и хакатоны</h2>
      <table>
        <tbody>
          <tr>
            <td>Предстоящие</td>
            <td><LedNumber number={1} size={23}/></td>
          </tr>
          <tr>
            <td>Сейчас идет:</td>
            <td><LedNumber number={0} size={23}/></td>
          </tr>
        </tbody>
      </table>
      <button onClick={() => {me.props.setSide(3);}} style={{ marginTop: "14px", background: "var(--white)", color: "var(--main-color)"}}>УЧАСТВОВАТЬ</button>
      <button onClick={() => {me.props.setSide(2);}} style={{ marginTop: "14px", color: "var(--white-black)"}}>МЕРОПРИЯТИЯ</button>
    </div>
  }
}