import React from "react";
import "./grants.scss";
import LedNumber from "../../components/cool_stuff/led_number/LedNumber";

export default class Grants extends React.Component<any, any> {
  render() {
    const me = this;
    return <div className={"grants"}>
      <h3>Гранты</h3>
      <div className={"flex flex-row justify-between items-center content-center"}>
        <p>Сейчас активно</p>
        <LedNumber number={1} size={30} />
      </div>
      <br/>
      <div className={"flex flex-row justify-between"}>
        <img style={{width: "120px"}}
             src={"https://fasie.ru//local/templates/.default/markup/img/logo_new.svg"}
             alt={"СТАРТ ИИ-1"} />
        <a target={"_blank"} href={"https://fasie.ru/press/fund/start-ai-2-8/"}>СТАРТ ИИ-1</a>
      </div>
      <br/>
      <br/>
      <br/>
    </div>
  }
}